@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.ListIcon {
    @include icon-grey-hover-effect;
    @include rtl-icon;

    cursor: pointer;

    &_isActive {
        color: var(--color-black);
    }
}
