@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.ImageZoomPopup {
    height: 100%;

    &.Popup {
        height: 100%;
    }

    &-PopupContent.Popup-Content {
        max-height: 100vh;
        height: 100vh;
        max-width: 100vw;
        width: 100%;
        padding: 0;

        .Popup-CloseBtn {
            inset-inline-end: 10px;
            inset-block-start: 10px;
        }
    }

    .ProductGallery {
        align-self: flex-start;
        height: 100%;
        padding-block-end: calc(100vh - 48px);

        &-SliderWrapper {
            height: 100%;
        }
    }

    .Slider-Arrow {
        display: none;
    }
}
