@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --header-logo-width: 75px;
    --header-logo-height: 20px;

    --header-top-menu-height: 64px;
    --header-height: 100px;
    --header-nav-height: 70px;
    --header-welcome-mobile-height: 30px;
    --header-total-height: calc(
        var(--header-height) + var(--header-top-menu-height) + var(--offline-notice-height) + var(--demo-notice-height) + env(safe-area-inset-top)
    );

    @include desktop {
        --header-logo-width: 130px;
        --header-logo-height: 35px;

        --header-nav-height: 120px;
        --header-height: var(--header-nav-height);
    }
}

@mixin button-invisible {
    margin: 0;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
}

@mixin button-visible {
    text-align: center;
    display: inline-block;
    opacity: 1;
    pointer-events: all;
    overflow: visible;
    cursor: pointer;
    color: var(--color-black);

    &:hover {
        .Header-ButtonTitle {
            color: var(--link-hover);
        }
    }

    @include tablet {
        height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    @include mobile {
        height: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }

    .Header-ButtonTitle {
        font-size: 12px;
        line-height: 2;
        display: block;
        color: map-get($default-neutral, 500);
    }
}

@mixin border-header {
    &::after {
        position: absolute;
        inset-block-end: 0;
        inset-inline-start: 18px;
        width: calc(100% - 32px);
        content: '';
        border-block-end: 1px solid var(--primary-divider-color);
    }

    @include desktop {
        &::after {
            inset-inline-start: 32px;
            width: calc(100% - 64px);
        }
    }
}

.Header {
    align-items: center;
    background: var(--color-white);
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding-block-start: env(safe-area-inset-top, 0);
    width: 100%;
    position: fixed;
    z-index: 100;
    inset-block-start: var(--demo-notice-height);

    > * {
        max-width: var(--content-wrapper-width);
        width: 100%;
    }

    &_name {
        &_menu,
        &_search,
        &_menu_subcategory {
            border-block-end: none;
        }

        &_category {
            position: fixed;

            @include mobile {
                border-block-end: none;
            }
        }
    }

    &-Button {
        @include button-invisible;

        &_isVisible {
            @include mobile {
                @include button-visible;
            }
        }

        &_isDisabled {
            pointer-events: none;
            opacity: 0.1;
        }

        &_type {
            &_menu,
            &_minicart,
            &_account,
            &_search {
                @include button-visible;

                margin-inline-start: 24px;

                @include desktop {
                    margin-inline-start: 35px;

                    > svg {
                        height: 24px;
                        width: auto;
                    }
                }

                @include mobile {
                    > svg {
                        height: 16px;
                        width: auto;
                    }
                }
            }

            &_menu {
                @include tablet {
                    > svg {
                        height: 16px;
                        width: auto;
                    }
                }

                @include mobile {
                    > svg {
                        height: 12px;
                        width: auto;
                    }
                }
            }
        }

        &[aria-hidden='true'] {
            display: none;
        }
    }

    &-LogoWrapper {
        inset-block-end: 0;
        height: var(--header-logo-height);
        inset-inline-start: 0;
        opacity: 1;
        max-width: 100%;
        overflow: hidden;
        inset-inline-end: 0;
        inset-block-start: 0;
        width: var(--header-logo-width);

        @include mobile {
            margin-inline-end: auto;
        }

        @include tablet {
            margin-inline-end: auto;
        }
    }

    &-Minicart {
        &ItemCount {
            background: var(--primary-base-color);
            border-radius: 25px;
            color: var(--color-white);
            height: 17px;
            padding: 2px 4px;
            position: absolute;
            width: min-content;
            min-width: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 11px;
            line-height: 2;
            font-weight: 700;

            @include mobile {
                inset-block-start: -15px;
                inset-inline-end: -8px;
            }

            @include desktop {
                inset-block-start: -18px;
                inset-inline-end: -10px;
            }
        }
    }

    &-Nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: var(--header-nav-height);

        @include mobile {
            flex-wrap: wrap;
        }

        @include desktop {
            @include border-header;
        }
    }

    &-Welcome {
        font-size: 14px;
        overflow: hidden;
        text-transform: capitalize;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 16px;

        @include mobile {
            max-width: var(--content-wrapper-width);
            height: var(--header-welcome-mobile-height);
            padding-inline: 18px;
            margin-inline: auto;

            @include border-header;
        }

        @include desktop {
            max-width: 200px;
            margin-inline-start: 50px;
            margin-inline-end: auto;
        }

        > span {
            color: map-get($default-neutral, 500);
        }
    }

    &-TopMenu {
        justify-content: flex-end;
        height: var(--header-top-menu-height);
        padding-block: 16px;

        @include border-header;
    }

    &-TopMenu,
    &-Nav {
        display: flex;
        margin: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 18px;

        &_isAuthorization {
            max-width: inherit;

            &::after {
                inset-inline-start: 0;
                width: 100%;
            }
        }

        @include desktop {
            padding-inline: 32px;
        }
    }

    &-Wrapper {
        display: flex;
        justify-content: center;
        margin-block-end: var(--header-total-height);

        &_isAuthorization {
            margin-block-end: var(--header-top-menu-height);
        }

        &_isPrerendered {
            @include mobile {
                .Header-TopMenu,
                .Menu-MenuWrapper {
                    display: none;
                }

                .Header-Nav {
                    & > * {
                        display: none;
                    }

                    .Header {
                        &-Title,
                        &-LogoWrapper,
                        &-Button {
                            &_isVisible {
                                display: block;
                            }
                        }
                    }
                }
            }
        }
    }
}
