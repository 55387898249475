@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --content-wrapper-width: 1440px;
    --content-wrapper-padding-inline: 32px;

    @include mobile {
        --content-wrapper-padding-inline: 18px;
    }
}

.ContentWrapper {
    max-width: var(--content-wrapper-width);
    padding-inline: var(--content-wrapper-padding-inline);
    margin-inline: auto;
}
