@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.NoMatch {
    opacity: 0;
    animation: appear 100ms 300ms forwards;
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        min-height: calc(100vh - var(--header-total-height) - var(--breadcrumbs-height) - 4 * var(--border-width));
        margin-block-end: 0;
        margin-block-start: 0;
    }

    &-Button {
        --button-height: 56px;
        --button-hover-height: 56px;

        margin-block-start: 24px;

        @include mobile {
            margin-block-start: 28px;
        }
    }

    &-Subtitle {
        font-size: 36px;
        font-weight: 700;
        margin: 0;
        line-height: 48px;

        @include mobile {
            font-size: 24px;
            line-height: 36px;
        }
    }

    &-Wrapper {
        text-align: center;
    }
}
