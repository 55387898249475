@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.WDTPopup {
    --button-height: 56px;
    --button-hover-height: 56px;
    --hollow-button-height: 56px;
    --hollow-button-hover-height: 56px;

    @include desktop {
        --popup-min-width: 910px;
    }

    @include tablet {
        --popup-min-width: 710px;
    }

    .Button {
        width: 100%;

        &:not(:last-of-type) {
            margin-block-end: 24px;
        }
    }

    .Popup-Header {
        @include mobile {
            display: block;
            padding-block: 32px 16px;
            border-bottom: none;

            .Popup-CloseBtn {
                display: none;
            }
        }
    }

    .Popup-Heading {
        text-transform: none;
    }

    &-Content {
        @include mobile {
            background-color: map-get($default-neutral, 50);
        }
    }

    &-Data {
        margin-block-end: 32px;

        & > .Field-Wrapper {
            grid-column: 1/3;
        }

        & > *:not(.WDTPopup-Errors) {
            &:nth-child(odd) {
                color: map-get($default-neutral, 500);

                @include mobile {
                    margin-block-end: 4px;
                    font-size: 14px;
                    line-height: 2;
                }
            }

            &:nth-child(even) {
                @include mobile {
                    margin-block-end: 16px;
                }
            }
        }

        @include desktop {
            margin-block: 24px 48px;
            display: inline-grid;
            grid-template-columns: repeat(2, auto);
            grid-gap: 32px 48px;
            align-items: center;
        }
    }

    &-Errors {
        display: grid;
        grid-column: 1 / span 2;

        @include mobile {
            margin-block-end: 16px;
        }

        .Notification {
            max-width: 100%;

            &:last-of-type {
                margin-block-end: 0;
            }

            &:not(&:last-of-type) {
                margin-block-end: 16px;
            }

            @include mobile {
                &:not(&:last-of-type) {
                    margin-block-end: 8px;
                }
            }
        }
    }
}
