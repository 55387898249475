@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

@import "../DatePicker/DatePicker.style";

.FieldDatePicker {
    .CalendarIcon {
        position: absolute;
        inset-block-start: 50%;
        inset-inline-start: var(--input-padding-inline);
        transform: translateY(-50%);
        z-index: 1;
        color: map-get($default-secondary, 800);
    }

    &-Button {
        padding: 8px;
        border-top: 1px solid map-get($default-neutral, 400);

        .Button {
            margin-block-start: 8px;
            width: 100%;

            &::before {
                content: '';
                position: absolute;
                inset-block-start: -10px;
                inset-inline: -10px;
                height: 1px;
                background: map-get($default-neutral, 400);
            }
        }
    }

    input {
        padding-inline-start: calc(var(--input-padding-inline) * 2 + 12px);
    }
}
