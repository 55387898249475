@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --reset-button-color: #{map-get($default-neutral, 500)};
    --reset-button-hover-color: var(--color-black);
}

.ResetButton {
    display: flex;

    &-Button {
        display: flex;
        align-items: center;
        margin-inline: auto;
        color: var(--reset-button-color);
        cursor: pointer;
        font-weight: 400;

        &:hover,
        &:focus {
            color: var(--reset-button-hover-color);
        }

        .TrashIcon {
            margin-inline-end: 13px;
        }
    }
}
