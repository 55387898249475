@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --search-criteria-background: var(--secondary-base-color);
    --search-criteria-label-color: rgba(10, 10, 10, 0.44);
    --search-criteria-search-term-color: #0a0a0a;
}

.SearchOverlay {
    &_isVisible {
        .SearchOverlay {
            &-Results {
                display: block;
            }
        }
    }

    &-Results {
        background: var(--color-white);
        display: none;
        width: 100%;
        padding-block: 0;
        padding-inline: 0;
        z-index: -1;

        h4,
        p {
            font-size: 14px;
            font-weight: 500;
            line-height: 2;
            margin-block: 0;
            text-transform: inherit;
            color: var(--color-black);

            @include tablet {
                font-size: 14px;
                font-weight: 500;
                line-height: 2;
                margin-block: 0;
                text-transform: inherit;
                color: var(--color-black);
            }

            @include mobile {
                font-size: 14px;
                font-weight: 500;
                line-height: 2;
                margin-block: 0;
                text-transform: inherit;
                color: var(--color-black);
            }
        }

        p {
            font-weight: 400;

            @include mobile {
                text-align: center;
            }
        }

        @include desktop {
            margin-block-start: -1px;
            padding-block: 24px;
            padding-inline: 24px 0;
            position: absolute;
            width: 100%;
            border: 1px solid var(--input-border-color);

            ul {
                max-height: 400px;
                overflow-y: auto;
            }
        }
    }
}
