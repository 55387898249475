@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.ArrangementPage {
    &-Wrapper {
        padding-block-end: 32px;

        @include desktop {
            padding-block-end: 64px;
        }
    }
}
