:root {
    --default-input-padding-inline: 20px;
    --default-input-padding-block: 10px;
    --default-input-label-padding-inline: 8px;
    --input-height: 48px;
    --input-border-radius: var(--imported_input_border_radius, 0);
    --input-padding-inline: var(--imported_input_padding, var(--default-input-padding-inline));
    --input-padding-block: var(--imported_input_padding, var(--default-input-padding-block));
    --input-background-color: var(--imported_input_background_color, #{$white});
    --input-border-color: var(--imported_input_border_color, #{map-get($default-neutral, 300)});
    --input-border-color-focus: #{map-get($default-neutral, 900)};
    --input-color: var(--imported_input_color, #{map-get($default-neutral, 900)});
    --input-color-placeholder: var(--imported_input_color, #{map-get($default-neutral, 500)});
    --input-font-size: var(--imported_paragraph_font_size, 1.6rem);
    --input-font-weight: var(--imported_paragraph_font_weight, 400);
    --input-font-style: var(--imported_paragraph_font_style, normal);
    --input-line-height: var(--imported_paragraph_line_height, 1.75);
    --input-number-width: 30px;
    --input-number-height: 32px;
    --input-number-button-width: 22px;
    --input-number-button-height: 32px;

    @include mobile {
        --input-font-size: var(--imported_paragraph_font_size, 1.4rem);
        --input-font-weight: var(--imported_paragraph_font_weight, 400);
        --input-font-style: var(--imported_paragraph_font_style, normal);
        --input-line-height: var(--imported_paragraph_line_height, 2);
    }
}

input,
textarea,
select {
    height: var(--input-height);
    font-size: var(--input-font-size);
    font-weight: var(--input-font-weight);
    font-style: var(--input-font-style);
    line-height: var(--input-line-height);
    text-overflow: ellipsis;
    border: 1px solid var(--input-border-color);
    background-color: var(--input-background-color);
    border-radius: var(--input-border-radius);
    color: var(--input-color);
    padding-inline: var(--input-padding-inline);
    padding-block: var(--input-padding-block);
    appearance: none;
    caret-color: var(--input-color);
    width: 100%;
    opacity: 1;

    &:focus {
        --input-border-color: var(--input-border-color-focus);
    }

    &:not(:first-of-type) {
        margin-block-start: 12px;

        @include mobile {
            margin-block-start: 14px;
        }
    }

    &:-webkit-autofill {
        box-shadow: 0 0 0 30px var(--input-background-color) inset !important;
        background-color: var(--input-background-color) !important;
    }
}

textarea {
    width: 300px;

    @include mobile {
        width: 100%;
    }
}

[type='text'] {
    @include mobile {
        width: 100%;
    }
}

[data-input-number],
[type='number'] {
    -moz-appearance: textfield;
    color: var(--input-color-placeholder);
    border: {
        inset: logical 1px solid var(--input-border-color) 0 1px solid var(--input-border-color);
    }

    padding: 0;
    order: 2;
    text-align: center;
    height: var(--input-number-height);
    width: var(--input-number-width);

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        appearance: none;
        margin: 0;
    }

    ~ button {
        background: var(--input-background-color);
        border: 1px solid var(--input-border-color);
        border-radius: var(--button-border-radius);
        color: $black;
        inset-inline-start: 0;
        line-height: 0;
        margin: 0;
        order: 1;
        display: flex;
        height: var(--input-number-button-height);
        width: var(--input-number-button-width);
        align-items: center;
        justify-content: center;
        position: relative;
        inset-block-start: 0;

        svg {
            fill: var(--input-color-placeholder);
        }

        &:hover {
            svg {
                fill: var(--primary-base-color);
            }
        }

        &:first-of-type {
            inset-inline-start: 0;
            border-inline-start: 0;
            order: 3;
        }

        &:last-of-type {
            inset-block-end: 0;
            inset-inline-end: 0;
            border-inline-end: 0;
            order: 1;
        }

        &:disabled {
            svg {
                fill: var(--input-color-placeholder);
                cursor: not-allowed;
            }
        }

        span {
            @include mobile {
                inset-block-start: -2px;
            }
        }
    }
}
