@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --popup-background: #0003;
    --popup-content-background: #{$white};
    --popup-header-border-color: #{map-get($default-neutral, 100)};
    --popup-min-width: 100%;
    --popup-content-padding: 16px;
    --popup-header-padding: 16px;

    @include desktop {
        --popup-min-width: 559px;
        --popup-header-padding: 24px 32px;
        --popup-content-padding: 24px 32px;
    }
}

.Popup {
    opacity: 0;
    pointer-events: none;
    z-index: 400;
    position: fixed;
    background-color: var(--popup-background);
    inset-inline-start: 0;
    inset-block-start: var(--header-total-height);
    display: flex;
    justify-content: center;
    align-items: center;

    @include mobile {
        align-items: stretch;
    }

    &_isVisible {
        pointer-events: all;
        opacity: 1;
        width: 100vw;
        height: 100vh;
        inset-block-start: 0;

        @include mobile {
            inset-block-start: var(--header-total-height);
            height: calc(100% - var(--header-total-height));
        }
    }

    &-Heading {
        font-size: 16px;
        line-height: 1.75;
        font-weight: 600;
        text-decoration: none;
        margin-block: 0;
    }

    &-Header {
        padding: var(--popup-header-padding);
        border-bottom: 1px solid var(--popup-header-border-color);
    }

    &-Main {
        padding: var(--popup-content-padding);
    }

    &-Content {
        background-color: var(--popup-content-background);
        min-width: var(--popup-min-width);
        max-width: calc(var(--content-wrapper-width) * 0.8);
        max-height: 80%;
        overflow-y: auto;

        @include tablet {
            max-width: calc(var(--content-wrapper-width) * 0.4);
        }

        @include mobile {
            border-radius: 0;
            max-height: 100%;
        }
    }

    & &-CloseBtn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        height: 24px;
        width: 24px;
        inset-block-start: 50%;
        inset-inline-end: 24px;
        transform: translateY(-50%);
        z-index: 5;

        @include mobile {
            inset-inline-end: 16px;
        }
    }

    .Loader {
        @include mobile {
            position: fixed;
        }
    }

    .NotificationList {
        width: 100vw;
        inset-block-start: 0;
        inset-inline-start: calc(0px - var(--popup-content-padding));
        position: relative;
    }
}

.scrollDisabled {
    margin-block-start: 0;
    width: 100%;

    @include desktop {
        position: fixed;
        overflow-y: auto;
    }

    @include mobile {
        overflow-y: hidden;
    }
}
