@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.CmsPage {
    --footer-total-height: 0px;

    &_isBreadcrumbsHidden {
        --breadcrumbs-height: 64px;

        margin-block-start: var(--header-total-height);
        padding-block-start: 30px;

        @include desktop {
            padding-block-start: 50px;
        }
    }

    &-Content {
        .cms-content {
            @include default-list;
            @include table-cms;
            @include heading-cms;
        }
    }

    &-Wrapper {
        grid-template-columns: auto;
        max-width: var(--content-wrapper-width);
        padding-inline: 32px;
        margin-block-end: var(--header-nav-height);
        margin-inline: auto;
        padding-block-end: 64px;

        @include mobile {
            padding-inline: 16px;
            padding-block-end: 32px;
        }

        &_page_width {
            &_full {
                max-width: 100%;
                padding-inline: 0;
            }
        }
    }

    &-Heading {
        margin-block-end: 16px;

        @include desktop {
            margin-block-end: 8px;
        }
    }

    &-SectionPlaceholder {
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        margin: 0;
        margin-block-end: 10px;
        padding-block-end: 30%;

        @include mobile {
            padding-block-end: 60%;
        }

        &:first-child {
            padding-block-end: 40%;

            @include mobile {
                padding-block-end: 100%;
            }
        }
    }

    .TextPlaceholder {
        margin-block-end: 22px;
        display: block;

        @include mobile {
            margin-block-end: 25px;
        }
    }

    .Slider-Crumbs {
        position: unset;
        margin-block: 16px 40px;

        @include mobile {
            margin-block: 20px 28px;
        }
    }

    .widget {
        overflow-x: auto;
    }
}
