@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --default-notification-height: 54px;
    --notification-info-color: var(--imported_notificationInfo_color, var(--primary-info-color));
    --notification-info-background: var(--imported_notificationInfo_background_color, var(--secondary-info-color));
    --notification-info-height: var(--imported_notificationInfo_height, var(--default-notification-height));
    --notification-error-color: var(--imported_notificationError_color, var(--primary-error-color));
    --notification-error-background: var(--imported_notificationError_background_color, var(--secondary-error-color));
    --notification-error-height: var(--imported_notificationError_height, var(--default-notification-height));
    --notification-success-color: var(--imported_notificationSuccess_color, var(--primary-success-color));
    --notification-success-background: var(
        --imported_notificationSuccess_background_color,
        var(--secondary-success-color)
    );
    --notification-success-height: var(--imported_notificationSuccess_height, var(--default-notification-height));
    --notification-warning-color: var(--primary-warning-color);
    --notification-warning-background: var(--color-white);
    --notification-warning-height: var(--imported_notificationError_height, var(--default-notification-height));
}

.Notification {
    max-width: 400px;
    padding: 15px 16px;
    border-radius: 0;
    border: 1px solid var(--notification-color);

    &-Wrapper {
        display: flex;
        align-items: center;

        svg {
            display: inline-block;
            margin-inline-end: 16px;
            height: 24px;
            width: 24px;
            min-width: 24px;
        }
    }

    &_type {
        &_error {
            --notification-color: var(--notification-error-color);
            --notification-background: var(--notification-error-background);
            --notification-height: var(--notification-error-height);
        }

        &_success {
            --notification-color: var(--notification-success-color);
            --notification-background: var(--notification-success-background);
            --notification-height: var(--notification-success-height);
        }

        &_info {
            --notification-color: var(--notification-info-color);
            --notification-background: var(--notification-info-background);
            --notification-height: var(--notification-info-height);
        }

        &_warning {
            --notification-color: var(--notification-warning-color);
            --notification-background: var(--notification-warning-background);
            --notification-height: var(--notification-warning-height);
        }
    }

    &_is {
        &_opening {
            animation: fadeInStart var(--animation-duration) cubic-bezier(.645, .045, .355, 1) forwards;
        }

        &_closing {
            animation: fadeOutStart var(--animation-duration) cubic-bezier(.645, .045, .355, 1) forwards;
        }
    }
}
