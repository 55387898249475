@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --color-pagination-link-color: var(--imported_pagination_color, #{$black});

    --color-pagination-link-active-color: var(--color-white);
    --color-pagination-link-active-background: var(--primary-base-color);

    --color-pagination-link-hover-color: var(--imported_pagination_color, #{$black});
    --color-pagination-link-hover-background: #{map-get($default-neutral, 100)};

    --pagination-line-height: var(--imported_pagination_line_height, 1.5);
    --pagination-font-weight: var(--imported_pagination_font_weight, 400);
}

.PaginationLink {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    border: 0;
    border-radius: 0;
    margin-inline-end: 9px;
    font-weight: var(--pagination-font-weight);

    @include button-transition;

    &:hover:not(.PaginationLink_isArrow) {
        text-decoration: none;
        color: var(--color-pagination-link-hover-color);
        border-color: var(--color-pagination-link-hover-background);
        background-color: var(--color-pagination-link-hover-background);
    }

    &_isCurrent {
        color: var(--color-pagination-link-active-color);
        border-color: var(--color-pagination-link-active-background);
        background-color: var(--color-pagination-link-active-background);
    }
}
