@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --checkbox-border-radius: var(--imported_checkboxes_border_radius, 0);
    --checkbox-border-color: var(--imported_checkboxes_border_color, var(--input-color));
    --checkbox-border-color-active: var(--imported_checkboxes_border_color, var(--color-white));
    --checkbox-width: var(--imported_checkboxes_width, 18px);
    --checkbox-height: var(--imported_checkboxes_height, 18px);
    --radio-border-color: var(--imported_checkboxes_border_color, var(--input-color));
    --radio-border-color-active: var(--imported_checkboxes_border_color, var(--primary-base-color));
    --required-text-color: #dc6d6d;
}

.Field {
    margin-block-start: 0;

    &_isDisabled {
        --button-background-color: #{map-get($default-neutral, 100)};
        --input-background-color: #{map-get($default-neutral, 100)};
        --input-border-color: #{map-get($default-neutral, 100)};
        --input-color: #{map-get($default-neutral, 300)};
        --input-color-placeholder: #{map-get($default-neutral, 300)};

        input,
        select,
        textarea,
        .FieldSelect {
            cursor: not-allowed;
        }

        [class*='Icon'] {
            &:not(.ChevronIcon) {
                fill: #{map-get($default-neutral, 300)};
            }

            color: #{map-get($default-neutral, 300)};
        }
    }

    &:not(&_isDisabled) {
        &-Wrapper {
            &_readOnly {
                [data-input-number],
                [type='number'] {
                    pointer-events: none;
                }
            }
        }
    }

    &-Note {
        margin-inline-start: var(--input-padding-inline);
        margin-block-start: 4px;
    }

    &-Label {
        font-size: var(--input-font-size);
        font-weight: var(--input-font-weight);
        font-style: var(--input-font-style);
        line-height: var(--input-line-height);

        @include mobile {
            font-size: var(--input-font-size);
            font-weight: var(--input-font-weight);
            font-style: var(--input-font-style);
            line-height: var(--input-line-height);
        }
    }

    &-CheckboxLabel,
    &-RadioLabel {
        align-items: flex-start;

        &:not([class*='_isDisabled']):hover {
            color: var(--input-color)
        }

        * {
            font-size: var(--input-font-size);
            font-weight: var(--input-font-weight);
            font-style: var(--input-font-style);
            line-height: var(--input-line-height);

            @include mobile {
                font-size: var(--input-font-size);
                font-weight: var(--input-font-weight);
                font-style: var(--input-font-style);
                line-height: var(--input-line-height);
            }

            a {
                z-index: 15;
            }
        }
    }

    &-ErrorMessages {
        color: var(--primary-error-color);
        line-height: 2;
        font-weight: 400;
        margin-block-start: 4px;
        margin-inline-start: var(--input-padding-inline);
    }

    &_type {
        &_email,
        &_text,
        &_textarea,
        &_password,
        &_select {
            [disabled] {
                background-color: var(--input-background-color);
                cursor: not-allowed;
            }
        }

        &_number {
            &.Field {
                &_hasError {
                    button,
                    input {
                        border-color: var(--primary-error-color);
                    }
                }
            }
        }

        &_text,
        &_email,
        &_password {
            &.Field_floatingLabel {
                input:not(input:placeholder-shown),
                input:focus {
                    & + .Field-LabelContainer {
                        inset-block-start: 0;

                        .Field-Label {
                            font-size: 11px;
                            line-height: 2;

                            &_isRequired {
                                color: var(--required-text-color);
                            }
                        }
                    }
                }

                &.Field {
                    &_isValid {
                        .Field-Label {
                            color: var(--primary-success-color);
                        }
                    }

                    &_hasError {
                        .Field-Label {
                            color: var(--primary-error-color);
                        }
                    }
                }

                .Field {
                    &-LabelContainer {
                        position: absolute;
                        inset-block-start: 50%;
                        inset-inline-start: var(--input-padding-inline);
                        transform: translate(-8px, -50%);
                        transition: top 200ms ease;
                        padding-inline: 8px;
                    }

                    &-Label {
                        font-size: var(--input-font-size);
                        font-weight: var(--input-font-weight);
                        font-style: var(--input-font-style);
                        line-height: var(--input-line-height);
                        color: var(--input-color-placeholder);
                        padding-block-end: inherit;
                        transition: font-size 200ms ease;
                        user-select: none;
                        cursor: text;

                        &::before {
                            content: '';
                            position: absolute;
                            inset-block-end: 0;
                            inset-inline-start: 0;
                            height: 50%;
                            width: 100%;
                            padding-inline: var(--default-input-label-padding-inline);
                            transform: translate(calc(var(--default-input-label-padding-inline) * -1), -5%);
                            z-index: -1;
                            background-color: var(--color-white);
                        }

                        &_isRequired {
                            color: var(--required-text-color);
                        }
                    }
                }
            }
        }

        &_password {
            & > input {
                padding-inline-end: calc(var(--default-input-padding-inline) * 3);
            }

            & > button {
                margin: 0;
                cursor: pointer;
                position: absolute;
                inset-block-start: 50%;
                height: 20px;
                width: 20px;
                transform: translateY(-50%);
                inset-inline-end: var(--default-input-padding-inline);

                svg {
                    height: 100%;
                    width: 100%;
                }
            }

            &.Field_visiblePassword {
                & > span {
                    height: 24px;
                    width: 24px;
                    transform: translate(2px, -50%);
                }
            }
        }

        &_date {
            &::placeholder {
                color: var(--input-color-placeholder);
            }
        }
    }

    select {
        width: 100%;
    }

    [type='checkbox'],
    [type='radio'] {
        + .input-control {
            @include desktop {
                min-width: var(--checkbox-width);
                width: var(--checkbox-width);
            }
        }
    }

    [type='checkbox'] {
        + .input-control {
            border-width: 1px;
        }

        &:hover:not(:disabled) + .input-control {
            --box-color: var(--color-white);

            cursor: pointer;
            border-color: var(--primary-base-color);

            &::after {
                --checkmark-color: var(--color-white);
            }
        }

        &:checked:not(:disabled) + .input-control {
            --box-color: var(--primary-base-color);

            cursor: pointer;
            border-color: var(--primary-base-color);

            &::after {
                transition: none;
                border-color: var(--primary-base-color);
                --checkmark-color: var(--checkbox-border-color-active);
            }

            &_indeterminate {
                &::after {
                    inset-block-start: 50%;
                    inset-inline-start: 50%;
                    transform: translate(-50%, -50%);
                    box-shadow: none;
                    height: calc(var(--checkbox-height) / 8);
                    width: calc(var(--checkbox-width) / 3);
                }
            }
        }
    }

    [type='radio'] {
        + .input-control {
            display: flex;
            align-items: center;
            justify-content: center;
            border-width: 1px;
            border-radius: 50%;
            margin-block-start: 4px;

            &::after {
                position: static;
                border-radius: 50%;
                box-shadow: none;
                height: 6px;
                width: 6px;
            }
        }

        &:hover:not(:disabled) + .input-control {
            --box-color: var(--color-white);

            cursor: pointer;
            border-color: var(--radio-border-color-active);

            &::after {
                --checkmark-color: var(--color-white);
            }
        }

        &:checked:not(:disabled) + .input-control {
            --box-color: var(--color-white);

            cursor: pointer;
            border-color: var(--radio-border-color-active);

            &::after {
                border-color: var(--radio-border-color-active);
                --checkmark-color: var(--radio-border-color-active);
            }
        }
    }

    &File {
        &-Button {
            width: 100%;

            @include desktop {
                width: 145px;
            }
        }

        &-Remove {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 14px;
            width: 14px;

            .CloseIcon {
                height: 9px;
                width: 9px;
            }
        }

        &-List {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin-block-start: 16px;

            li {
                &::before {
                    display: none;
                }

                display: inline-grid;
                grid-template-columns: auto 1fr auto;
                align-items: center;
                grid-column-gap: 8px;
                padding-inline-start: 0;
                margin-block-end: 16px;
                margin-inline-end: auto;
                font-size: 12px;
                line-height: 2;
                text-decoration: underline;
            }
        }

        &-AllowedTypes {
            font-size: 12px;
            line-height: 2;
        }
    }

    [type='file'] {
        padding-block: 0;
        padding-inline: 0;
        border-style: dashed;
        cursor: pointer;
        height: 32px;
        width: 100%;
        opacity: 0;
        z-index: 1;

        + label {
            display: flex;
            align-items: center;
            flex-direction: row;
            justify-content: flex-start;
            position: absolute;
            inset: 0;
            width: 100%;
            height: auto;
            cursor: pointer;
            border: none;
            padding-inline: 0;
            padding-block: 0;
            color: map-get($default-secondary, 800);

            span {
                display: block;
                color: map-get($default-secondary, 800);
                margin-inline-start: 8px;
                font-size: 14px;
                font-weight: 600;
                line-height: 1.75;
                text-transform: initial;
            }
        }
    }
}
