/* poppins-regular - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/poppins-v20-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/poppins-v20-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-latin-ext_latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-latin-ext_latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-latin-ext_latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-latin-ext_latin-regular.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-500 - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/poppins-v20-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/poppins-v20-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-latin-ext_latin-500.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-latin-ext_latin-500.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-latin-ext_latin-500.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-latin-ext_latin-500.svg#Poppins') format('svg'); /* Legacy iOS */
}
/* poppins-600 - latin-ext_latin */
@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/poppins-v20-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/poppins-v20-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/poppins-v20-latin-ext_latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/poppins-v20-latin-ext_latin-600.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/poppins-v20-latin-ext_latin-600.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/poppins-v20-latin-ext_latin-600.svg#Poppins') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 300;
    src: url('../fonts/barlow-v12-latin-ext_latin-300.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/barlow-v12-latin-ext_latin-300.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/barlow-v12-latin-ext_latin-300.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/barlow-v12-latin-ext_latin-300.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/barlow-v12-latin-ext_latin-300.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/barlow-v12-latin-ext_latin-300.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-regular - latin-ext_latin */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/barlow-v12-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/barlow-v12-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/barlow-v12-latin-ext_latin-regular.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/barlow-v12-latin-ext_latin-regular.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/barlow-v12-latin-ext_latin-regular.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/barlow-v12-latin-ext_latin-regular.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-500 - latin-ext_latin */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 500;
    src: url('../fonts/barlow-v12-latin-ext_latin-500.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/barlow-v12-latin-ext_latin-500.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/barlow-v12-latin-ext_latin-500.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/barlow-v12-latin-ext_latin-500.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/barlow-v12-latin-ext_latin-500.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/barlow-v12-latin-ext_latin-500.svg#Barlow') format('svg'); /* Legacy iOS */
}
/* barlow-600 - latin-ext_latin */
@font-face {
    font-family: 'Barlow';
    font-style: normal;
    font-weight: 600;
    src: url('../fonts/barlow-v12-latin-ext_latin-600.eot'); /* IE9 Compat Modes */
    src: local(''), url('../fonts/barlow-v12-latin-ext_latin-600.eot?#iefix') format('embedded-opentype'),
        /* IE6-IE8 */ url('../fonts/barlow-v12-latin-ext_latin-600.woff2') format('woff2'),
        /* Super Modern Browsers */ url('../fonts/barlow-v12-latin-ext_latin-600.woff') format('woff'),
        /* Modern Browsers */ url('../fonts/barlow-v12-latin-ext_latin-600.ttf') format('truetype'),
        /* Safari, Android, iOS */ url('../fonts/barlow-v12-latin-ext_latin-600.svg#Barlow') format('svg'); /* Legacy iOS */
}
