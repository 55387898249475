@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.ArrangementHeader {
    .MyAccountHeader {
        &-Body {
            padding-inline: 16px;
            padding-block: 32px;

            @include desktop {
                padding-inline: 32px;
                padding-block: 32px;
            }
        }

        &-Children {
            display: grid;
            grid-row-gap: 24px;
            align-items: flex-start;
            grid-template-areas: 'link'
                'name'
                'list'
                'other';

            @include desktop {
                grid-template-areas: 'name name link'
                    'list list list'
                    'other other other';
            }
        }
    }

    &-Name,
    &-Link {
        @include desktop {
            margin-block-end: 14px;
        }
    }

    &-Name {
        grid-area: name;
        grid-column-gap: 16px;
    }

    &-List {
        grid-area: list;

        @include mobile {
            display: grid;
            grid-row-gap: 24px;
        }

        @include desktop {
            display: flex;
            flex-wrap: wrap;
        }
    }

    &-Item {
        @include desktop {
            padding-inline-end: 24px;
            grid-column-gap: 8px;

            &:not(:last-of-type) {
                @include separator(null, 12px);
            }
        }
    }

    &-Other {
        grid-area: other;

        @include mobile {
            max-width: 75%;
            margin: auto;
            text-align: center;
        }

        @include desktop {
            display: inline-grid;
            grid-column-gap: 8px;
            grid-template-columns: auto auto;
            justify-self: flex-start;
        }
    }

    &-Name,
    &-Item {
        @include desktop {
            display: inline-grid;
            grid-template-columns: auto auto;
            justify-self: flex-start;
        }

        @include mobile {
            display: grid;
            grid-template-columns: minmax(auto, 1fr) 50%;
            grid-column-gap: 16px;
            width: 100%;
        }
    }

    &-Link {
        grid-area: link;
        display: flex;
        align-items: center;

        @include mobile {
            margin-block-end: 8px;

            &_tradeAgreement {
                margin-block-end: 32px;
            }
        }

        @include desktop {
            justify-self: flex-end;
        }

        .ChevronIcon {
            margin-inline-start: 8px;
            height: 10px;
            width: 10px;
        }

        .ChangesIcon {
            margin-inline-end: 10px;
            height: 18px;
            width: 18px;
        }
    }

    &-Other,
    &-List {
        font-size: 14px;
        line-height: 2;
    }

    &-Label {
        color: map-get($default-neutral, 500);
    }
}
