@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.PriceListMessage {
    --notification-color: #993d00;

    max-width: initial;
    width: 100%;
    background-color: var(--notification-color);

    .Notification-Wrapper {
        align-items: flex-start;
        width: 100%;

        & > *:not(svg) {
            flex: 1;
        }

        * {
            color: $white;
        }

        .Notification-Text {
            word-break: break-word;
            font-weight: var(--paragraph-font-weight);

            @include mobile {
                font-weight: var(--paragraph-font-weight-mobile);
            }
        }
    }
}
