@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.Tooltip {
    display: inline-flex;

    &-Header {
        display: flex;
        cursor: pointer;
    }

    &-Content {
        opacity: 0;
        visibility: hidden;
        transition: opacity 250ms ease, inset 250ms ease;
        will-change: opacity, inset;
        z-index: 2;

        .Notification {
            margin-block: 0;

            &-Text {
                margin-inline-end: 0;
            }
        }

        &_isVisible {
            opacity: 1;
            visibility: visible;
        }
    }
}
