:root {
    --table-head-color: #{map-get($default-neutral, 500)};
    --table-body-border-color: #{map-get($default-neutral, 200)};
    --table-body-border: 1px solid;
}

table {
    thead th {
        font-size: 11px;
        color: var(--table-head-color);
    }

    th {
        border-block-start: var(--table-body-border);
        border-block-start-color: var(--table-body-border-color);
    }

    td,
    th {
        font-size: 12px;
        font-weight: 400;
        line-height: 1.5;
        height: 54px;
        padding: 10px 16px;
        text-align: start;
        vertical-align: middle;
        border-block-end: var(--table-body-border);
        border-block-end-color: var(--table-body-border-color);
        border-inline-end: var(--table-body-border);
        border-inline-end-color: var(--table-body-border-color);
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-of-type {
            border-inline-start: var(--table-body-border);
            border-inline-start-color: var(--table-body-border-color);
        }
    }
}

.Table-Wrapper {
    max-width: 100%;
    overflow-x: auto;
}
