@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.Pagination {
    z-index: inherit;

    .ChevronIcon {
        cursor: pointer;
        display: block;
        height: 14px;
        width: 10px;

        &:hover,
        &:focus {
            fill: none;
            color: var(--primary-base-color);
        }
    }
}
