$default-neutral: (
    10: #f7f7f7,
    50: #f2f2f2,
    100: #e5e5e5,
    200: #cccccc,
    300: #b2b2b2,
    400: #999999,
    500: #808080,
    600: #666666,
    700: #4d4d4d,
    800: #333333,
    900: #161925,
    1000: #040406,
);

$default-primary: (
    50: #ffe0cc,
    100: #ffe0cc,
    200: #ffc299,
    300: #ffa366,
    400: #ff8533,
    500: #ff6600,
    600: #cc5200,
    700: #993d00,
    800: #662900,
    900: #331400,
);

$default-secondary: (
    50: #f0f5ff,
    100: #e1ebff,
    200: #aec9fe,
    300: #7ba8fe,
    400: #4986fe,
    500: #1665fd,
    600: #024ddf,
    700: #013bac,
    800: #012a7a,
    900: #011847,
);

$default-success: (
    100: #eefbf0,
    200: #c4f3cb,
    300: #9aeaa6,
    400: #70e181,
    500: #45d85b,
    600: #29c240,
    700: #209832,
    800: #176e24,
    900: #0e4316,
);

$default-error: (
    100: #ffecd2,
    200: #ffd3a6,
    300: #ffb579,
    400: #ff9758,
    500: #ff6721,
    600: #db4818,
    700: #b72f10,
    800: #931a0a,
    900: #7a0c06,
);

$white: #ffffff;
$black: #040406;

$default-primary-text-color: $white;
$default-primary-base-color: map-get($default-primary, 500);
$default-primary-dark-color: map-get($default-primary, 600);
$default-primary-light-color: map-get($default-primary, 400);

$default-secondary-text-color: $white;
$default-secondary-base-color: map-get($default-secondary, 600);
$default-secondary-dark-color: map-get($default-secondary, 800);
$default-secondary-light-color: map-get($default-secondary, 400);

$default-success-text-color: $white;
$default-success-base-color: map-get($default-success, 600);
$default-success-dark-color: map-get($default-success, 700);
$default-success-light-color: map-get($default-success, 500);

$default-error-text-color: $white;
$default-error-base-color: map-get($default-error, 700);
$default-error-dark-color: map-get($default-error, 800);
$default-error-light-color: map-get($default-error, 600);

$font-poppins: 'Poppins', sans-serif;
$font-standard-size: 62.5%;
