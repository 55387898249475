@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --arrangement-element-margin-block: 8px;
    --arrangement-text-font-size: 11px;
    --arrangement-text-line-height: 2;
    --arrangement-text-color: var(--color-black);
    --arrangement-field-font-size: 12px;
    --arrangement-field-line-height: 2;
    --arrangement-field-padding-inline: 8px;
    --arrangement-field-padding-block: 6px;
}

.ArrangementTable {
    --input-number-width: 100%;
    --input-number-height: 36px;
    --input-number-button-height: 36px;
    --input-padding-inline: var(--arrangement-field-padding-inline);
    --input-padding-block: var(--arrangement-field-padding-block);
    --input-height: 36px;
    --input-font-size: var(--arrangement-field-font-size);
    --input-line-height: var(--arrangement-field-line-height);
    --table-body-border-color: #{map-get($default-neutral, 100)};

    &-Wrapper {
        --button-hover-height: 36px;
        --button-height: 36px;
        --button-padding: 9px 12px;
        --button-hover-padding: 9px 12px;

        @include mobile {
            padding-inline: var(--content-wrapper-padding-inline);
            margin-inline: calc(var(--content-wrapper-padding-inline) * -1);
            max-width: calc(100% + var(--content-wrapper-padding-inline) * 2);
        }

        .Button {
            font-size: var(--arrangement-text-font-size);
            line-height: var(--arrangement-text-line-height);
            text-transform: none;
            white-space: nowrap;
        }
    }

    .Loader {
        @include mobile {
            inset-inline: calc(var(--content-wrapper-padding-inline) * -1);
        }
    }

    .FieldDatePicker {
        & .CalendarIcon {
            width: 10px;
        }

        .input-control {
            padding-inline-start: calc(var(--input-padding-inline) * 2 + 10px);
        }
    }

    .FieldSelect {
        &-Select {
            padding-inline-end: 20px;
        }

        & .ChevronIcon {
            width: 5px;
        }
    }

    .Notification {
        --notification-height: 0;

        padding-inline: 16px;
        padding-block: 8px;

        svg {
            height: 12px;
            width: 12px;
            min-width: 12px;
            margin-inline-end: 12px;
        }

        p {
            font-size: var(--arrangement-field-font-size);
            font-weight: 400;
            line-height: var(--arrangement-field-line-height);
        }
    }

    .ProductTags {
        margin-block-start: 2px;

        .Tooltip:not(:last-of-type) {
            margin-inline-end: 8px;
            margin-block: -8px 8px;
        }

        &-Item {
            padding-inline: 6px;
            padding-block: 2px;
            font-size: var(--arrangement-text-font-size);
            line-height: var(--arrangement-text-line-height);
            white-space: nowrap;
        }
    }

    &-DeliveryAddress {
        font-size: var(--arrangement-text-font-size);
        line-height: var(--arrangement-text-line-height);
        color: var(--arrangement-text-color);
        text-align: start;
        cursor: pointer;
        min-width: 114px;

        .ChevronIcon {
            height: 8px;
            color: var(--primary-base-color);
        }
    }

    &-Link {
        display: block;
        font-size: 9px;
        line-height: var(--arrangement-text-line-height);
        color: map-get($default-neutral, 700);

        &:first-of-type {
            font-size: var(--arrangement-text-font-size);
            line-height: var(--arrangement-text-line-height);
            color: var(--arrangement-text-color);

            &:hover {
                color: var(--primary-base-color);
            }
        }
    }

    &-Table {
        @at-root {
            table#{&} {
                td {
                    & > *:not(:last-child) {
                        margin-block-end: var(--arrangement-element-margin-block);
                    }

                    &.ArrangementTable-Table {
                        &_destination {
                            ul:not(.FieldSelect-Options) {
                                li {
                                    padding-inline-start: 0;
                                    color: map-get($default-neutral, 300);

                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                        }

                        &_number,
                        &_destination,
                        &_productActions {
                            ul:not(.FieldSelect-Options) {
                                li:not(:last-child) {
                                    padding-block-end: 18px;
                                }
                            }
                        }

                        &_material {
                            word-break: break-all;
                        }

                        &_quantityOpen {
                            text-transform: lowercase;
                        }

                        &_actions {
                            position: sticky;
                            inset-inline-end: 0;
                            background: $white;
                        }

                        &_price {
                            text-align: center;

                            .Tooltip {
                                width: 100%;

                                &-Header {
                                    margin-inline: auto;

                                    .WarningIcon {
                                        height: 12px;
                                        width: 12px;
                                    }
                                }
                            }
                        }
                    }
                }

                th,
                td {
                    padding-inline: 8px;
                    padding-block: 16px;
                    border-right: none;
                    font-size: var(--arrangement-text-font-size);
                    line-height: var(--arrangement-text-line-height);
                    color: var(--arrangement-text-color);
                    border-top: 0;

                    ul:not(.FieldSelect-Options) {
                        li {
                            margin-block-end: 0;
                            font-size: var(--arrangement-text-font-size);
                            line-height: var(--arrangement-text-line-height);
                        }
                    }

                    &.ArrangementTable-Table {
                        &_destination {
                            @include size(130px);

                            & > .Button {
                                padding-inline: var(--input-padding-inline);
                                padding-block: var(--input-padding-block);
                                width: 100%;
                                justify-content: flex-start;
                                text-align: start;
                                font-size: var(--input-font-size);
                                font-weight: var(--input-font-weight);
                                font-style: var(--input-font-style);

                                @include button-disabled;
                            }
                        }

                        &_material {
                            @include size(auto, 158px, 195px);
                        }

                        &_number {
                            @include size(auto, 100px, 130px);

                            .Field-Wrapper {
                                width: 114px;
                            }
                        }

                        &_price {
                            @include size(auto, 100px, inherit);
                        }

                        &_incoterms {
                            @include size(120px);
                        }

                        &_quantityOpen {
                            @include size(auto, 75px, inherit);
                        }

                        &_deliveryType {
                            @include size(120px);
                        }

                        &_deliveryAddress {
                            @include size(auto, 140px, 200px);
                        }

                        &_productActions {
                            @include size(133px);

                            .Field-Wrapper {
                                width: 117px;
                            }
                        }

                        &_deliveryType,
                        &_deliveryAddress,
                        &_productActions,
                        &_number {
                            ul:not(.FieldSelect-Options) {
                                li {
                                    padding-inline-start: 0;

                                    &::before {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    &:first-of-type,
                    &:nth-child(2) {
                        border-left: 0;
                        padding-inline-start: 0;
                    }

                    &:last-of-type {
                        padding-inline-end: 0;
                    }
                }
            }
        }
    }
}
