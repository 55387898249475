@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.Breadcrumb {
    font-size: 14px;
    line-height: 2;
    word-break: break-all;
    margin-block-end: 0;
    padding-inline-start: 0;

    &:last-child {
        margin-block-end: 0;

        .Breadcrumb-Link {
            color: var(--breadcrumbs-active-color);
        }
    }

    .ChevronIcon {
        width: 10px;
        height: 10px;
        inset-block-end: 0;
    }

    &-Link {
        font-size: 14px;
        line-height: 2;
    }
}
