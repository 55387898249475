@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.Sidebar {
    position: fixed;
    inset-block: calc(var(--demo-notice-height) + env(safe-area-inset-top)) env(safe-area-inset-bottom);
    inset-inline-start: 0;
    z-index: 200;
    background-color: map-get($default-neutral, 50);
    text-overflow: ellipsis;
    will-change: width, transform;

    @at-root .isSidebarVisible {
        .Header,
        .Footer,
        .Checkout,
        .Breadcrumbs,
        .MyAccount,
        .ArrangementPage,
        main[class*='Page'] {
            /* stylelint-disable */
            & > [class*='page_width_full'] {
                @media (min-width: 1025px) {
                    padding-left: calc(64px + env(safe-area-inset-left, 0));
                }

                @media (min-width: 1025px) and (max-width: 1500px) {
                    max-width: calc(100% + (64px + env(safe-area-inset-left, 0)));
                    margin-left: calc((64px + env(safe-area-inset-left, 0)) * -1);
                }
            }

            @media (min-width: 1025px) and (max-width: 1500px) {
                padding-left: calc(64px + env(safe-area-inset-left, 0));
            }
            /* stylelint-enable */
        }
    }

    &:not(&_root) {
        z-index: 300;
    }

    @include desktop {
        width: 64px;
        transition: width ease 250ms;
    }

    @include tablet {
        width: 233px;
        transition: transform ease 250ms;
        transform: translateX(-100%);
    }

    @include mobile {
        padding-block-start: 48px;
        inset-block-start: calc(var(--demo-notice-height) + var(--header-total-height) + env(safe-area-inset-top));
        width: 100%;
        transition: transform ease 250ms;
        transform: translateX(-100%);
    }

    &_open {
        @include desktop {
            width: 233px;

            .Sidebar-Label {
                visibility: visible;
            }

            .Sidebar-AdditionalHeader {
                visibility: visible;
            }
        }

        @include tablet {
            transform: translateX(0);
        }

        @include mobile {
            transform: translateX(0);
        }

        .Sidebar-Footer {
            padding: 24px 16px;
        }
    }

    &_hasContent {
        @include mobile {
            padding-block-start: 0;
        }

        .Sidebar-Header {
            @include mobile {
                padding-block: 32px;
            }

            @include desktop {
                padding-block-end: 50px;
            }
        }
    }

    .Loader {
        z-index: 1;
    }

    &-Overflow {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    }

    &-AdditionalClose {
        position: absolute;
        inset-inline-end: 16px;
        inset-block-start: 32px;
        height: 24px;
        width: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-AdditionalHeader {
        width: 233px;
        padding-inline: 16px;
        padding-block-end: 64px;
        visibility: hidden;

        &::after {
            content: '';
            position: absolute;
            inset-inline-start: 16px;
            inset-block-end: 32px;
            height: 1px;
            width: calc(100% - 32px);
            background-color: map-get($default-neutral, 100);
        }

        @include tablet {
            visibility: visible;
        }

        @include mobile {
            visibility: visible;
            width: 100%;
        }
    }

    &-Label {
        font-size: 14px;
        font-weight: 400;
        text-align: start;
        color: var(--color-black);
        visibility: hidden;

        @include desktop {
            width: 160px;
        }

        @include tablet {
            visibility: visible;
        }

        @include mobile {
            visibility: visible;
        }
    }

    &-Header {
        padding-inline-start: 16px;
        padding-block: 45px;
        width: 233px;

        .Sidebar-Label {
            font-size: 16px;
            font-weight: 500;
        }

        @include mobile {
            width: 100%;
        }
    }

    &-Icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 56px;
        width: 64px;

        > svg {
            height: 100%;
        }
    }

    &-Tooltip {
        display: flex;
        align-items: center;
        background-color: map-get($default-neutral, 10);
        padding-block: 8px;
        padding-inline: 16px;
        font-size: 14px;
        font-weight: 400;
        text-align: start;
        color: var(--color-black);
        height: 56px;
        width: 192px;
    }

    &-Link {
        display: flex;
        align-items: center;
        height: 56px;
        margin-block-end: 20px;
        color: var(--color-black);
        cursor: pointer;

        &:hover,
        &:focus {
            color: var(--color-black);
            background-color: map-get($default-neutral, 10);
        }

        &:not(.Sidebar-Link_hasIcon) {
            .Sidebar-Label {
                padding-inline-start: 20px;
            }
        }

        &_isLogout {
            height: 64px;
            margin-block-end: 0;

            .Sidebar-Icon {
                height: 64px;
            }
        }

        &_active {
            color: var(--primary-base-color);

            &:hover,
            &:focus {
                color: var(--primary-base-color);

                .Tooltip-Content {
                    color: var(--primary-base-color);
                }
            }

            > .Sidebar-Label {
                color: var(--primary-base-color);
            }
        }
    }

    &-Body {
        max-width: 233px;
        width: 233px;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;

        @include mobile {
            max-width: 100%;
            width: 100%;
        }
    }

    &-Footer {
        @include mobile {
            padding: 24px 16px;
        }

        & > .Button {
            min-width: 200px;
            width: 100%;
            --button-border-width: 1px;
            --hollow-button-border: var(--imported_hollowButtons_border_color, #{map-get($default-neutral, 300)});
            --hollow-button-color: var(--imported_hollowButtons_color, #{map-get($default-neutral, 500)});
            --hollow-button-hover-background: var(--imported_hollowHoverButtons_background_color, tranparent);
            --hollow-button-hover-border: var(--imported_hollowHoverButtons_border_color, var(--color-black));
            --hollow-button-hover-color: var(--imported_hollowHoverButtons_color, var(--color-black));

            .LogoutIcon {
                margin-inline-end: 15px;
            }
        }
    }

    &-Toggle {
        position: absolute;
        inset-block-start: 35px;
        inset-inline-end: 0;
        transform: translateX(50%);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 48px;
        width: 48px;
        background-color: var(--color-black);
        color: var(--color-white);
        cursor: pointer;
        z-index: 2;

        @include tablet {
            display: none;
        }

        @include mobile {
            display: none;
        }
    }
}
