@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --footer-nav-height: 0;
    --footer-height: 64px;
    --footer-total-height: var(--footer-height);
}

.Footer {
    padding-block: 18px;
    background-color: map-get($default-neutral, 50);

    @include mobile {
        padding-block: 32px 24px;
    }

    &-Wrapper {
        @include desktop {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 64px;
        }
    }

    &-Content {
        @include desktop {
            display: flex;
            flex-wrap: wrap;
            column-gap: 64px;
            row-gap: 8px;
        }

        a {
            display: block;
            font-weight: 500;
            color: map-get($default-neutral, 600);

            @include mobile {
                &:not(&:last-of-type) {
                    margin-block-end: 32px;
                }
            }
        }
    }

    &-Copyright {
        color: map-get($default-neutral, 400);

        @include mobile {
            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 1px;
                margin-block: 24px;
                background-color: map-get($default-neutral, 100);
            }
        }
    }
}
