@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

$datepicker__background-color: map-get($default-neutral, 100);
$datepicker__border-color: map-get($default-neutral, 400);
$datepicker__highlighted-color: $default-success-base-color;
$datepicker__muted-color: map-get($default-neutral, 400);
$datepicker__selected-color: $default-primary-base-color;
$datepicker__range-background-color: map-get($default-secondary, 50);
$datepicker__include_dates-background: $black;
$datepicker__text-color: $black;
$datepicker__header-color: $black;
$datepicker__navigation-disabled-color: lighten($datepicker__muted-color, 10%);

$datepicker__font-family: 'Barlow', sans-serif;
$datepicker__border-radius: 0;
$datepicker__day-before-width: 8px;
$datepicker__day-margin: 2px $datepicker__day-before-width;
$datepicker__font-size: 12px;
$datepicker__item-size: 24px;
$datepicker__margin: 2px $datepicker__day-before-width;
$datepicker__navigation-button-size: 24px;

@import '~react-datepicker/src/stylesheets/datepicker';

.react-datepicker {
    z-index: 0;

    &__with-time-select {
        min-width: 357px;
    }
}

.react-datepicker__children {
    z-index: -1;
}

.react-datepicker-popper * {
    font-family: $datepicker__font-family;
}

.react-datepicker-popper {
    z-index: 20;
}

.react-datepicker__header {
    background-color: $white;
    border-bottom: none;
    padding-block: 10px;

    &::after {
        content: '';
        position: absolute;
        inset-block-end: 0;
        inset-inline: 4px;
        height: 1px;
        background-color: map-get($default-neutral, 200);
    }
}

.react-datepicker__navigation {
    inset-block-start: 13px;

    &--previous {
        &:not(.react-datepicker__navigation--next--with-time) {
            inset-inline-start: 6px;
        }
    }

    &--next {
        &:not(.react-datepicker__navigation--next--with-time) {
            inset-inline-end: 6px;
        }
    }
}

.react-datepicker__navigation-icon {
    &--previous,
    &--next {
        background-image: url('src/style/icons/chevron-icon.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 11px;
        height: 10px;
        inset-inline-start: 0;

        &::before {
            display: none;
        }
    }

    &--previous {
        transform: rotate(180deg);
    }
}

.react-datepicker__triangle {
    &::before {
        border-bottom-color: map-get($default-neutral, 400);
    }

    &::after {
        border-bottom-color: $white !important;
    }
}

.react-datepicker__current {
    &-month {
        font: {
            size: 1.4rem;
            weight: 600;
        }

        text-transform: capitalize;
        line-height: 2;
    }
}

.react-datepicker__month {
    margin: 8px 0;
}

.react-datepicker__day {
    height: $datepicker__item-size;
    line-height: calc(#{$datepicker__item-size} - 2px);
    margin-block: 0;

    &-name {
        font: {
            size: 1.2rem;
            weight: 500;
        }

        text-transform: capitalize;
    }

    &--include-dates {
        background-color: $datepicker__range-background-color;
        color: $datepicker__text-color;

        &:not(:first-of-type) {
            &::before {
                content: '';
                position: absolute;
                background-color: $datepicker__range-background-color;
                inset-block-start: 0;
                inset-inline-start: calc((#{$datepicker__day-before-width} * 2) * -1);
                width: calc(#{$datepicker__day-before-width} * 2);
                height: 100%;
            }
        }
    }

    &:not(&--include-dates) + &--include-dates {
        &::before {
            display: none;
        }
    }

    &--today {
        font-weight: normal;

        &:not(.react-datepicker__day--include-dates) {
            background-color: $datepicker__background-color;

            &:not(:hover) {
                color: $datepicker__text-color;

                &.react-datepicker__day--disabled {
                    color: map-get($default-neutral, 400);
                }
            }

            &:hover {
                background-color: $datepicker__selected-color;

                &.react-datepicker__day--disabled {
                    background-color: $datepicker__background-color;
                }
            }
        }
    }

    &--selected {
        background-color: $datepicker__selected-color;
        color: $white;
    }

    &--in-selecting-range,
    &--in-range {
        background-color: $datepicker__range-background-color;
        color: $datepicker__text-color;

        &::before {
            content: '';
            position: absolute;
            background-color: $datepicker__range-background-color;
            inset-block-start: 0;
            inset-inline-start: calc((#{$datepicker__day-before-width} * 2) * -1);
            width: calc(#{$datepicker__day-before-width} * 2);
            height: 100%;
        }
    }

    &--selecting-range-start,
    &--range-start {
        background-color: $datepicker__selected-color;
        color: $white;

        &::before {
            display: none;
        }
    }

    &--selecting-range-end,
    &--range-end {
        background-color: $datepicker__selected-color;
        color: $white;
    }

    &--in-selecting-range,
    &--in-range,
    &--selecting-range-start,
    &--range-start,
    &--selecting-range-end,
    &--range-end {
        &:hover {
            color: $white;
        }
    }
}

.react-datepicker__week {
    & > .react-datepicker__day:first-child {
        &::before {
            display: none;
        }
    }
}

.react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 75px;
}

.react-datepicker__time {
    &-list {
        &-item {
            font-family: $datepicker__font-family;
            font-size: $datepicker__font-size;
            color: $datepicker__text-color;
            line-height: $datepicker__item-size;
            height: 35px !important;
            margin-block-end: 0;

            &::before {
                display: none;
            }

            &:last-child {
                margin-block-end: 0;
            }
        }
    }
}
