@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.Accordion {
    --accordion-before-header-backgroud: var(--primary-base-color);
    --accordion-before-header-border: var(--primary-base-color);
    --accordion-before-header-color: var(--color-white);

    --accordion-header-backgroud: var(--color-white);
    --accordion-header-border: var(--hollow-button-border);
    --accordion-header-color: var(--hollow-button-color);

    --accordion-toggle-backgroud: var(--hollow-button-background);
    --accordion-toggle-border: var(--hollow-button-border);
    --accordion-toggle-color: var(--hollow-button-color);

    --accordion-toggle-hover-backgroud: var(--hollow-button-hover-background);
    --accordion-toggle-hover-border: var(--hollow-button-hover-border);
    --accordion-toggle-hover-color: var(--hollow-button-hover-color);

    --accordion-body-backgroud: var(--color-white);
    --accordion-body-border: #{map-get($default-neutral, 200)};

    &:not(:last-of-type) {
        margin-block-end: 32px;

        @include desktop {
            margin-block-end: 24px;
        }
    }

    & > .Accordion {
        margin-block-start: 32px;

        @include desktop {
            margin-block-start: 24px;
        }
    }

    &[data-before-header] {
        &::before {
            content: attr(data-before-header);
            display: inline-flex;
            align-items: center;
            height: 48px;
            padding-inline: 24px;
            font-weight: 600;
            background-color: var(--accordion-before-header-backgroud);
            color: var(--accordion-before-header-color);
            border: {
                style: solid;
                color: var(--accordion-before-header-border);
                width: 1px;
            }
        }
    }

    &-Toggle {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline-start: auto;
        height: 36px;
        width: 52px;
        cursor: pointer;

        @include button-transition;

        border: {
            style: solid;
            color: var(--accordion-toggle-border);
            width: 1px;
        }

        background-color: var(--accordion-toggle-backgroud);
        color: var(--accordion-toggle-color);

        @include desktop {
            height: 40px;
            width: 60px;
        }
    }

    &-Label {
        display: inline-flex;
        align-items: center;
        text-align: start;
        line-height: 1.5;
    }

    &-Header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        width: 100%;
        padding-inline: 24px;
        background-color: var(--accordion-header-backgroud);
        color: var(--accordion-header-color);
        border: {
            style: solid;
            color: var(--accordion-header-border);
            width: 1px;
        }

        cursor: pointer;
        font-weight: 600;
        font-size: var(--paragraph-font-size-mobile);
        font-style: var(--paragraph-font-style-mobile);
        line-height: var(--paragraph-line-height-mobile);

        @include desktop {
            height: 72px;
            font-size: var(--paragraph-font-size);
            font-style: var(--paragraph-font-style);
            line-height: var(--paragraph-line-height);
        }

        &:not([disabled]) {
            &:hover,
            &:focus {
                @include desktop {
                    .Accordion-Toggle {
                        background-color: var(--accordion-toggle-hover-backgroud);
                        border-color: var(--accordion-toggle-hover-border);
                        color: var(--accordion-toggle-hover-color);
                    }
                }
            }
        }
    }

    &-Body {
        z-index: 1;
        padding: 24px;
        background-color: var(--accordion-body-backgroud);
        border: {
            style: solid;
            color: var(--accordion-body-border);
            width: 1px;
        }

        border-top: 0;
    }
}
