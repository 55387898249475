@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.ArrangementSummary {
    --input-height: 56px;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    z-index: 0;

    @include desktop {
        align-items: flex-end;
    }

    .Button {
        --button-height: 56px;
        --button-hover-height: 56px;
        --hollow-button-height: 56px;
        --hollow-button-hover-height: 56px;
        --hollow-button-border: var(--hollow-button-background);
        --secondary-button-height: 56px;
        --secondary-button-hover-height: 56px;

        @include mobile {
            --button-padding: 13px 20px;
            --button-hover-padding: 13px 20px;
            --hollow-button-padding: 13px 20px;
            --hollow-button-hover-padding: 13px 20px;
            --secondary-button-padding: 13px 20px;
            --secondary-button-hover-padding: 13px 20px;
        }

        text-transform: initial;

        .CartIcon {
            height: 18px;
            width: 20px;
            margin-inline-end: 11px;
        }

        .CalendarIcon {
            height: 18px;
            width: 20px;
            margin-inline-end: 19px;
        }
    }

    &-Children {
        max-width: 540px;
        margin-block-end: 48px;

        .Field-CheckboxLabel {
            * {
                font-size: 12px;
                line-height: 2;

                &:last-of-type {
                    margin-block-end: 0;
                }
            }
        }
    }

    &-Summary {
        display: inline-block;
        padding-inline: 16px;
        padding-block: 16px;
        background-color: map-get($default-neutral, 50);
        font-size: 14px;
        line-height: 2;

        @include mobile {
            text-align: center;
        }

        @include desktop {
            padding-inline: 32px;
            padding-block: 22px;
            justify-self: flex-end;
            align-self: flex-start;
        }
    }

    &-Action {
        @include desktop {
            display: flex;
            justify-content: flex-end;
        }

        & > *:not(:first-child) {
            @include mobile {
                margin-block-start: 24px;
            }

            @include desktop {
                margin-inline-start: 32px;
            }
        }
    }

    &-GridButton {
        display: grid;
        grid-row-gap: 24px;
    }

    &-Heading {
        display: inline-block;

        @include mobile {
            margin-block-end: 4px;
        }
    }

    &-List {
        display: inline-flex;
        flex-wrap: wrap;
    }

    &-Item {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.75;
        padding-inline: 32px;

        @include desktop {
            &:last-of-type {
                padding-inline-end: 0;
            }
        }

        &:not(:last-of-type) {
            @include separator(null, 0);
        }
    }

    &-WithAttachment {
        display: grid;
        grid-gap: 24px;
        width: 100%;
        margin-block-end: 32px;

        .Field-Wrapper_type_file {
            margin-block-start: 20px;
            justify-content: flex-start;
        }

        @include desktop {
            &_isCart {
                grid-template-columns: 1fr 1fr;
            }

            margin-block-end: 48px;
        }
    }
}
