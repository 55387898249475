@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.ConfirmPopup {
    --popup-content-padding: 48px 16px;

    @include desktop {
        --popup-content-padding: 64px 111px;
    }

    @include tablet {
        --popup-content-padding: 48px 32px;
    }

    text-align: center;

    .Popup-CloseBtn {
        display: none;
    }


    .Popup-Content {
        @include desktop {
            max-width: calc(var(--content-wrapper-width) * 0.5);
        }
    }

    .ErrorIcon {
        @include mobile {
            display: none;
        }

        @include desktop {
            margin-block-end: 32px;
        }
    }

    &-Content {
        font-size: 20px;
        line-height: 1.75;
        margin-block-end: 48px;

        span {
            font-weight: 600;
            word-break: break-all;
        }

        @include desktop {
            margin-block-end: 64px;
        }
    }

    &-Actions {
        margin: 0 auto;
        grid-gap: 24px;
        display: inline-grid;

        .Button {
            --button-height: 56px;
            --button-hover-height: 56px;

            text-transform: initial;
        }

        @include mobile {
            width: 100%;
        }

        @include desktop {
            grid-template-columns: repeat(2, 1fr);
        }

        @include tablet {
            width: 100%;
            grid-template-columns: 1fr;
        }
    }

    &-Accept {
        --button-background: #{map-get($default-error, 600)};
        --button-hover-background: #{map-get($default-error, 600)};
        --button-border: #{map-get($default-error, 600)};
        --button-hover-border: #{map-get($default-error, 600)};
    }

    &-Cancel {
        --button-background: #{map-get($default-neutral, 300)};
        --button-hover-background: #{map-get($default-neutral, 300)};
        --button-border: #{map-get($default-neutral, 300)};
        --button-hover-border: #{map-get($default-neutral, 300)};
    }
}
