@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.StoreSwitcher {
    --input-height: 30px;
    --input-padding-block: 0;
    --input-padding-inline: 12px;
    --input-font-size: 12px;
    --input-line-height: 2;

    .FieldSelect-Select {
        padding-inline-end: 32px;
    }

    .ChevronIcon {
        width: 10px;
        height: 10px;
    }
}
