@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.ArrangementSidebar {
    &-AdditionalHeader {
        .SearchField {
            --input-height: 48px;

            width: 100%;
            max-width: initial;
        }

        &_isDepartments {
            .SearchField {
                margin-block-end: 24px;

                @include desktop {
                    margin-block-end: 32px;
                }
            }
        }
    }

    &-Link {
        display: flex;
        align-items: center;
        padding-inline: 16px;
        padding-block: 16px;
        min-height: 56px;
        width: 100%;
        margin-block-end: 20px;
        color: var(--color-black);
        cursor: pointer;

        @include desktop {
            width: 233px;
        }

        &:hover,
        &:focus {
            background-color: map-get($default-neutral, 10);
        }

        &_active {
            color: var(--primary-base-color);
        }
    }
}
