:root {
    --link-color: var(--imported_link_color, var(--primary-base-color));
    --link-hover: var(--imported_linkHover_color, var(--primary-base-color));
    --default-hover-link-underline: 2px solid var(--link-hover);
    --link-underline: var(--imported_link_border_bottom, none);
    --link-hover-underline: var(--imported_linkHover_border_bottom, var(--default-hover-link-underline));
}

a {
    border-block-end: var(--link-underline);
    color: var(--link-color);
    font-size: 14px;

    &:hover {
        color: var(--link-hover);
    }

    &.Link {
        @include button-transition;

        &:not(&_isSecondary) {
            &:hover,
            &:focus {
                text-decoration: underline;
                padding-block-end: 3px;
            }
        }

        &_isSecondary {
            --link-color: var(--secondary-dark-color);
            --link-hover: var(--secondary-base-color);

            @include desktop {
                &:hover {
                    text-decoration: underline;
                    text-underline-position: under;
                }
            }
        }
    }
}
