@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.FieldForm {
    &-Fields {
        margin-block-end: inherit;

        @include mobile {
            margin-block-end: inherit;
        }
    }
}
