@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.ArrangementActions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-block-end: 48px;

    .Tooltip-Content {
        & > .Notification {
            --notification-height: 0;

            padding-inline: 16px;
            padding-block: 8px;

            svg {
                height: 12px;
                width: 12px;
                min-width: 12px;
                margin-inline-end: 12px;
            }

            p {
                font-size: var(--arrangement-field-font-size);
                font-weight: 400;
                line-height: var(--arrangement-field-line-height);
            }
        }
    }

    .Field {
        &-Wrapper {
            margin-block-start: 0;

            &_type_text {
                @include mobile {
                    flex: 1 0 100%;
                    margin-block-end: 32px;
                }
            }
        }
    }

    &-Checkbox {
        @include desktop {
            padding-inline-start: 24px;

            @include separator(12px);
        }

        .input-control {
            margin-block-start: 4px;
        }
    }

    &-Input {
        display: flex;
        align-items: center;

        .Field {
            &-Wrapper {
                margin-block-start: 0;
            }

            &-Label {
                margin-inline-end: 8px;
                padding-block-end: 0;
                font-size: 14px;
                line-height: 2;
                color: map-get($default-neutral, 500);
            }
        }

        input {
            @include desktop {
                max-width: 173px;
            }
        }
    }
}
