$gap: 16px;

[data-content-type] {
    iframe {
        aspect-ratio: 16/9;
        width: 100%;
    }
}

[data-content-type='row'] {
    [data-appearance='contained'] {
        max-width: var(--content-wrapper-width);
        padding-inline: 32px;
        margin-inline: auto;

        @include mobile {
            padding-inline: 16px;
        }
    }

    [data-appearance='full-width'],
    [data-appearance='full-bleed'] {
        & > [data-element='inner'] {
            max-width: var(--content-wrapper-width);
            padding-inline: 32px;
            margin-inline: auto;

            @include mobile {
                padding-inline: 16px;
            }
        }
    }
}

.pagebuilder {
    &-mobile {
        &-hidden {
            @include mobile {
                @include visually-hidden;
            }
        }

        &-only {
            @include desktop {
                @include visually-hidden;
            }
        }
    }

    &-column {
        @include mobile {
            flex: 1 0 100%;
            width: 100%;
        }

        &-group {
            gap: $gap;

            @include mobile {
                flex-wrap: wrap;
            }
        }
    }
}
