@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --search-bar-max-width: 450px;
    --search-bar-width: 270px;
    --search-clear-background: var(--secondary-base-color);
    --search-field-background: var(--secondary-base-color);
    --search-field-border-color: var(--primary-divider-color);
    --search-field-color: var(--secondary-base-color);
    --search-field-visible-background: #fff;
    --search-input-desktop-border-color: #000;
}

.SearchField {
    --input-padding-inline: 24px;
    --input-padding-block: 13px;
    --input-label-padding-inline: 8px;
    --input-height: 54px;

    display: none;
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
    z-index: 2;

    &:not(&_onlyInput) {
        @include mobile {
            background: var(--color-white);
            position: fixed;
            inset: var(--header-total-height) 0;
            padding-inline: 16px;
            padding-block: 16px;
            width: 100%;
            z-index: 110;

            .SearchField-SearchInnerWrapper {
                margin-block-end: 16px;
            }
        }
    }

    @include desktop {
        display: block;
        width: 55%;
        max-width: 338px;
        margin: 0 100px 0 auto;
    }

    @include tablet {
        margin: 0 auto;
    }

    &_isVisible {
        display: block;
    }

    &_isActive {
        .SearchOverlay {
            &-Results {
                display: block;
            }
        }
    }

    &-SearchIcon {
        cursor: text;
    }

    &-CloseIcon,
    &-SearchIcon {
        position: absolute;
        inset-inline-end: 15px;
        height: 22px;
        width: 20px;
        inset-block-start: 50%;
        transform: translateY(-50%);

        &_isActive {
            color: var(--primary-base-color);
        }
    }

    &-Input {
        padding-inline-end: calc(2 * var(--input-padding-inline));
        width: 100%;

        &:focus + .SearchField-SearchIcon {
            color: var(--primary-base-color);
        }
    }
}
