@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.Consents {
    [data-appearance='full-width'],
    [data-appearance='full-bleed'],
    [data-appearance='contained'] {
        padding-inline: 0;
        margin-inline: auto;

        @include mobile {
            padding-inline: 0;
        }

        p:last-child {
            margin-block-end: 0;
        }
    }
}
