@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --search-item-divider-color: #eee;
    --search-item-title-color: #000;
}

.SearchItem {
    padding-inline-start: 0;
    margin-block: 0 16px;

    &:last-child {
        margin: 0;
    }

    &::before {
        content: none;
    }

    &-CustomAttribute {
        color: var(--color-black);
        font-size: 12px;
        font-weight: 400;
        opacity: 0.5;
    }

    &-Wrapper {
        display: grid;
        grid-template-columns: 48px calc(100% - 60px);
        grid-column-gap: 12px;
        align-items: flex-start;
    }
}
