@mixin grey-page-bg {
    @include mobile {
        background: #fff;
    }

    &::before {
        z-index: -1;
        content: '';
        position: fixed;
        inset-inline-start: 0;
        inset-inline-end: 0;
        inset-block-start: 0;
        inset-block-end: 0;
        background: var(--secondary-base-color);

        @include desktop {
            content: none;
        }
    }
}

@mixin default-list {
    ul,
    ol {
        margin-inline-start: 8px;

        li {
            &::before {
                inset-inline-start: -20px;
            }
        }
    }
}

@mixin description-tags {
    li,
    span,
    div,
    p,
    dt,
    dd,
    dl {
        @content;
    }
}

@mixin mobile-bottom-wrapper {
    @include mobile {
        @content;

        border-block-start: 1px solid var(--primary-divider-color);
        position: fixed;
        inset-inline-start: 0;
        width: 100%;
        z-index: 85;
        background-color: var(--color-white);
        padding-block-end: var(--footer-total-height);
        transition: transform 200ms cubic-bezier(0.47, 0, 0.745, 0.715);

        .hideOnScroll & {
            transform: translateY(var(--footer-nav-height));
        }
    }
}

@mixin table-cms {
    table {
        width: 100%;
    }
}

@mixin heading-cms {
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-block: 2em 0.25em;
    }
}

@mixin separator($left: 24px, $right: null) {
    &::before {
        content: '';
        position: absolute;
        inset-inline: $left $right;
        inset-block-start: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 14px;
        background-color: map-get($default-neutral, 300);
    }
}

@mixin size($width, $minWidth: null, $maxWidth: null) {
    width: $width;

    @if ($minWidth == false) {
        min-width: $width;
    }

    @else {
        min-width: $minWidth;
    }

    @if ($maxWidth == false) {
        max-width: $width;
    }

    @else {
        max-width: $maxWidth;
    }
}

@mixin visually-hidden {
    position: absolute !important;
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    margin: -1px !important;
    overflow: hidden !important;
    clip: rect(0, 0, 0, 0) !important;
    white-space: nowrap !important;
    border: 0 !important;
}
