@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.TextPlaceholder {
    display: block;

    &:not(:last-of-type) {
        margin-block-end: 16px;
    }

    &-Item {
        display: block;
        height: 16px;
        background-image: var(--placeholder-image);
        background-size: var(--placeholder-size);
        animation: var(--placeholder-animation);
        pointer-events: none;
        width: 100%;

        &:not(:last-of-type) {
            margin-block-end: 16px;
        }

        &_loaded {
            pointer-events: all;
        }

        &_length {
            &_short {
                width: 100px;
            }

            &_medium {
                width: 150px;
            }

            &_long {
                width: 200px;
            }

            &_paragraph {
                width: 100%;
            }
        }
    }
}
