* {
    scrollbar-width: thin;
    scrollbar-color: map-get($default-neutral, 200) map-get($default-neutral, 50);
}

*::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

*::-webkit-scrollbar-track {
    background: map-get($default-neutral, 50);
}

*::-webkit-scrollbar-thumb {
    background-color: map-get($default-neutral, 200);
    border-radius: 12px;
    border: 12px none $white;
}
