@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

:root {
    --breadcrumbs-height: 92px;
    --breadcrumbs-active-color: var(--color-black);
    --breadcrumbs-color: #{map-get($default-neutral, 500)};
}

.Breadcrumbs {
    $crumb-padding: 11px;
    $arrow-size: 4px;

    &-List {
        list-style: none;
        padding-block: 32px;
    }
}
