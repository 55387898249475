@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.FieldSelect {
    @include size(100%, false, 100%);

    &-Input {
        @include visually-hidden;
    }

    @at-root {
        .react-select {
            $select: &;

            body &__menu-portal {
                z-index: 40;
            }

            &__menu-portal,
            &-container {
                font-size: var(--input-font-size);
                font-weight: var(--input-font-weight);
                font-style: var(--input-font-style);
                color: var(--input-color);

                #{$select}__control {
                    flex-wrap: nowrap;
                    background-color: var(--input-background-color);
                    border-radius: var(--input-border-radius);
                    border: 1px solid var(--input-border-color);
                    cursor: pointer;

                    #{$select}__indicator {
                        & > .ChevronIcon_direction_top {
                            display: none;
                        }
                    }

                    &--menu-is-open {
                        #{$select}__indicator {
                            & > .ChevronIcon_direction_bottom {
                                display: none;
                            }

                            & > .ChevronIcon_direction_top {
                                display: block;
                                color: var(--input-border-color-focus);
                            }
                        }
                    }

                    &--menu-is-open,
                    &--is-focused {
                        --input-border-color: var(--input-border-color-focus);
                    }
                }

                #{$select}__value-container {
                    height: var(--input-height);
                    padding-inline: var(--input-padding-inline);
                    padding-inline-end: 0;
                    padding-block: var(--input-padding-block);
                    min-width: 40px;
                }

                #{$select}__indicator {
                    align-items: center;
                    justify-content: center;
                    height: var(--input-height);
                    width: 32px;

                    .ChevronIcon {
                        width: 10px;
                        height: 10px;
                        color: var(--color-gray);
                    }
                }

                #{$select}__input-container {
                    margin-block: 0;
                    margin-inline: 0;
                    padding-inline: 0;
                    padding-block: 0;
                }

                #{$select}__input {
                    height: auto;
                    cursor: pointer;
                }

                #{$select}__menu {
                    z-index: 40;
                    border-style: solid;
                    border-width: 0 1px 1px;
                    border-color: var(--input-border-color-focus);
                    background-color: var(--input-background-color);

                    &-notice {
                        &--no-options {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            min-height: var(--input-height);
                            padding-inline: var(--input-padding-inline);
                            padding-block: var(--input-padding-block);
                            background-color: var(--input-background-color);
                            line-height: 1.4;
                            text-align: start;
                        }
                    }
                }

                #{$select}__option {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    min-height: var(--input-height);
                    padding-inline: var(--input-padding-inline);
                    padding-block: var(--input-padding-block);
                    background-color: var(--input-background-color);
                    cursor: pointer;

                    &--label {
                        line-height: 1.4;
                    }

                    &--sub-label {
                        margin-block-start: 4px;
                        font-size: calc(var(--input-font-size) - 4px);
                        font-weight: var(--input-font-weight);
                    }

                    &:hover,
                    &:focus,
                    &--is-focused,
                    &--is-selected {
                        background: var(--color-white);
                        color: var(--primary-base-color);
                    }
                }
            }
        }
    }
}
