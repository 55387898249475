@import '../../style/abstract/font';
@import '../../style/abstract/variables';
@import '../../style/abstract/button';
@import '../../style/abstract/media';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';
@import '~@scandipwa/scandipwa/src/style/abstract/loader';

.UserSupportPopup {
    &-Section {
        &:not(:last-of-type) {
            margin-block-end: 32px;
        }

        &:last-of-type {
            margin-block-end: 24px;
        }
    }

    &-Heading {
        color: var(--primary-base-color);
        font-size: 14px;
        font-weight: 600;
        line-height: 1.75;
        margin-block-end: 16px;
    }

    &-Address {
        font-size: 14px;
        line-height: 2;
        margin-block-end: 16px;

        &:last-child {
            margin-block-end: 0;
        }

        &::before {
            color: var(--primary-base-color);
        }

        & > span {
            display: block;
        }
    }
}
